<template>
    <div>
        <div class="row">
            <div class="ml-3">
                <p class="font-weight-lighter">
                    &copy; {{ copyYear }} by Jeff Anderson. All rights reserved.
                </p>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Footer",
        data: function () {
          return {
            copyYear: new Date().getFullYear(),
          }
        },
    }
</script>

<style scoped>

</style>