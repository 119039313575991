<template>
    <div class="ml-3">
        <H2>PAGE NOT FOUND</H2>
        <p>Sorry that page can't be found. Choose from a category above or a recent article on the right.</p>
        <p>
        <img width="100%" src="../assets/man-lying-on-plants-2915216.jpg"
             alt="Page Not Found photo credit Oladimeji Ajegbile from Pexels">
        </p>
        <p>
        <a href="https://www.pexels.com/photo/man-lying-on-plants-2915216/">Photo</a> by Marlon Schmeiski from Pexels
        </p>
    </div>
</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>

<style scoped>

</style>