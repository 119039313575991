<template>
    <div>

        <div class="px-2">

            <div class="row ">
                <div class="col">
                    <h2>About Me</h2>
                    <p>I'm a lifelong student, teacher, enterprise IT architect, software developer, and data geek.
                        When I log out, I love motorcycle riding, camping, traveling, and racing.</p>
                </div>
            </div>

            <div class="row ">
                <div class="col">
                    <h2>Books</h2>
                    <p>Spring Web Essentials is a full-length e-book that will teach you how to build modern industrial-grade web services with Spring Boot supporting JavaScript frameworks like Angular, React, and Vue.</p>
                    <div class="text-center">
                        <a href="https://leanpub.com/springwebessentials">
                        <img width="100%" src="../assets/epub-cover-2.jpg"
                             alt="Spring Web Essentials book cover">
                        </a>
                    </div>
                    <a href="https://leanpub.com/springwebessentials" class="my-3 btn btn-primary btn-lg btn-block" role="button" aria-pressed="true">Buy now from Leanpub<i class="fas fa-cart-plus ml-2"></i></a>
                </div>
            </div>

            <div class="row ">
                <div class="col">
                    <h2>Certifications</h2>
                    <div class="text-center">
                        <img class="img-fluid mb-3" src="../assets/TallLogos-500w.png"
                             alt="Badges for Amazon Web Services (AWS) Certified Solutions Architect Professional, Security Specialty, Developer Associate, Cloud Practitioner, and AWS Academy Accredited Instructor">
                    </div>
                </div>
            </div>

            <div class="row ">
                <div class="col">
                    <h2>Contact Me</h2>
                    <p class="mx-1 my-2"><strong><a class="text-dark" href="mailto:me@jeff-anderson.com">me@jeff-anderson.com</a></strong></p>
                </div>
            </div>

            <TableOfContents/>

            <div class="row">
                <div class="col mr-2">
                    <a target="_blank" href="https://www.tastytrade.com/">
                        <img class="img-fluid" src="https://s3.us-east-2.amazonaws.com/public.jeff-anderson.com/tt-logo-original.png">
                    </a>
                    <p><a target="_blank" href="https://www.tastytrade.com/">tastytrade</a>
                        is one of the fastest growing online financial networks in the world. Follow along as our experts
                        navigate the markets, provide actionable trading insights, and teach you how to trade. With over 50
                        original segments, and over 20 personalities, we’ll help you take your trading to the next level,
                        whether you are new to trading or a seasoned veteran.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <a target="_blank" href="https://start.tastyworks.com#/login?referralCode=GPDA7QWWF7">
                        <img class="img-fluid" src="https://s3.us-east-2.amazonaws.com/public.jeff-anderson.com/logo-tastyworks-black.svg">
                        <img class="img-fluid" src="https://s3.us-east-2.amazonaws.com/public.jeff-anderson.com/logo-tagline.svg">
                    </a>
                    <div class="tastyworks_info">
                        Click
                        <a target="_blank" href="https://tastyworks.com">here to learn more</a>
                        and please use referral code GPDA7QWWF7 so I get credit when you
                        <a target="_blank" href="https://start.tastyworks.com#/login?referralCode=GPDA7QWWF7">
                            open an account!
                        </a>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
    import TableOfContents from "./TableOfContents";
    export default {
        name: "Sidebar",
        components: {TableOfContents}
    }
</script>

<style scoped>

</style>